const IMAGES = {
  logoMobile: require("./logo-mobile.png"),
  logoDesktop: require("./logo-desktop.png"),
  homepage: {
    heroBg: require("./homepage/hero-bg.png"),
    heroBgSVG: require("./homepage/hero-bg.svg"),
    heroImage1: require("./homepage/hero-image-1.png"),
    heroImage: require("./homepage/hero-image.png"),
    heroImage2: require("./homepage/hero-image-2.png"),
    catergoryNewBg: require("./homepage/category-new-bg.jpg"),
    categroryUsedBg: require("./homepage/category-used-bg.jpg"),
  },
  icons: {
    account: require("./icons/account.png"),
    accountSVG: require("./icons/account.svg"),
    cashOnDelivery: require("./icons/cash-on-delivery.png"),
    customerService: require("./icons/customer-service.png"),
    freeShipping: require("./icons/free-shipping.png"),
    hamburgerMenu: require("./icons/hambugerMenu.png"),
    hamburgerMenuSVG: require("./icons/hamburger.svg"),
    hamburger2: require("./icons/hamburger2.png"),
    quality: require("./icons/quality.png"),
    warranty: require("./icons/warranty.png"),
    cartSmall: require("./icons/cart-sm.png"),
    cartGreen: require("./icons/cart-green.png"),
    showMore: require("./icons/showMore.png"),
    arrowForward: require("./icons/arrow_forward.png"),
    arrowBackward: require("./icons/arrow_backward.png"),
    filter: require("./icons/filter.png"),
    search: require("./icons/search.png"),
    checkSuccessful: require("./icons/Check-icon.png"),
    blogStar: require("./icons/blog-star.png"),
    deliveryTruck: require("./icons/delivery-truck.png"),
  },
  productTypes: {
    accessories: require("./product-types/accessories.jpg"),
    phones: require("./product-types/phones.jpg"),
    laptops: require("./product-types/laptops.jpg"),
    desktops: require("./product-types/desktops.jpg"),
    lifestyle: require("./product-types/lifestyle.jpg"),
    spareParts: require("./product-types/spare-parts.jpg"),
    audio: require("./product-types/audio.jpg"),
    wearable: require("./product-types/wearable.jpg"),
    power: require("./product-types/power.jpg"),
  },
  adSlider: {
    banner1: require("./ad-slider/banner-1.png"),
    banner2: require("./ad-slider/banner-2.png"),
    banner3: require("./ad-slider/banner-3.png"),
    banner4: require("./ad-slider/banner-4.png"),
    banner1Small: require("./ad-slider/banner-1-small.png"),
    banner2Small: require("./ad-slider/banner-2-small.png"),
    banner3Small: require("./ad-slider/banner-3-small.png"),
    banner4Small: require("./ad-slider/banner-4-small.png"),
  },
  productDesc: {
    productMain: require("./product-description/product-main-view.png"),
    productSide: require("./product-description/product-side-view.png"),
    productBack: require("./product-description/product-back-view.png"),
  },
  about: {
    aboutMobile: require("./about/about-1.jpg"),
    about: require("./about/about.webp"),
    ourMission: require("./about/our-mission.jpg"),
  },
  payment: {
    paystack: require("./payment/paystack-logo.png"),
    flutterwave: require("./payment/flutterwave-logo.png"),
    payOnDelivery: require("./payment/delivery-van.png"),
  },
  err: {
    404: require("./error/404-1.png"),
  },
};

export default IMAGES;
